import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './001_App/styles/index.scss';
import App from '001_App/App';
import {ThemeProvider} from '001_App/providers/ThemeProvider';
import {ErrorBoundary} from '001_App/providers/ErrorBoundary';

import '006_Shared/config/i18next/i18n';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <ErrorBoundary>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </ErrorBoundary>
    </BrowserRouter>
);
