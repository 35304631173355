import {Route, Routes} from 'react-router-dom';
import React, {Suspense} from 'react';
import {routeConfig} from '006_Shared/config/routeConfig';
import {PageLoader} from '003_Widgets/PageLoader';

export const AppRouter = () => {
    return (
        <Routes>
            {Object.values(routeConfig).map(({path, element}) => (
                <Route
                    key={path}
                    path={path}
                    element={

                        <Suspense fallback={<PageLoader />}>
                            <div className="page-wrapper">
                                {element}
                            </div>
                        </Suspense>
                    }
                />
            ))}
        </Routes>
    );
};
