import cls from './ThemeSwitcher.module.scss';
import {classNames} from '006_Shared/lib/classNames';
import {Theme, useTheme} from '001_App/providers/ThemeProvider';
import {Button, ThemeButton} from '006_Shared/ui/Button/Button';
import {
    IconDarkComponent,
    IconLightComponent
} from '006_Shared/assets/icons/iconsComponent/iconComponents';

interface ThemeSwitcherProps {
    className?: string;
}

export const ThemeSwitcher = (props: ThemeSwitcherProps) => {

    const {className = ''} = props;

    const {theme, toggleTheme} = useTheme();

    return (
        <Button
            className={classNames(cls.ThemeSwitcher, {}, [className])}
            theme={ThemeButton.CLEAR}
            onClick={toggleTheme}
        >
            {theme === Theme.DARK ? <IconLightComponent /> : <IconDarkComponent />}
        </Button>
    );
};
