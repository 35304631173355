import cls from './Loader.module.scss';
import {classNames} from '006_Shared/lib/classNames';

interface LoaderProps {
    className?:string;
}

export const Loader = (props: LoaderProps) => {

    const {className = ''} = props;

    return (
        <div className={classNames(cls.Loader, {}, [className])}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
        </div>
    );
};
