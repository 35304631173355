import {classNames} from '006_Shared/lib/classNames';
import cls from './AppLink.module.scss';
import {Link, LinkProps} from 'react-router-dom';

export enum AppLinkThemes {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

interface AppLinkProps extends LinkProps {
    className?: string;
    theme?: AppLinkThemes;
}

export const AppLink = (props: AppLinkProps) => {

    const {
        to,
        className = '',
        children,
        theme = AppLinkThemes.PRIMARY,
        ...otherProps
    } = props;

    return (
        <Link
            to={to}
            className={classNames(cls.AppLink, {}, [className, cls[theme]])}
            {...otherProps}>
            {children}
        </Link>
    );
};

//todo: hover, активная ссылка.
