import {RouteProps} from 'react-router-dom';
import {AboutPage} from '002_Pages/AboutPage';
import {NotFoundPage} from '002_Pages/NotFoundPage';
import {MainPage} from '002_Pages/MainPage';

enum AppRoutesNames {
    MAIN = 'main',
    ABOUT = 'about',
    NOT_FOUND = 'not_found'
}

const RoutePath: Record<AppRoutesNames, string> = {
    [AppRoutesNames.MAIN]: '/',
    [AppRoutesNames.ABOUT]: '/about',
    [AppRoutesNames.NOT_FOUND]: '*',
}
export const routeConfig: Record<AppRoutesNames, RouteProps> = {
    [AppRoutesNames.MAIN]: {
        path: RoutePath.main,
        element:
            <MainPage />
    },

    [AppRoutesNames.ABOUT]: {
        path: RoutePath.about,
        element:
            <AboutPage />
    },

    [AppRoutesNames.NOT_FOUND]: {
        path: RoutePath.not_found,
        element:
            <NotFoundPage />
    },
}
