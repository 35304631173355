import cls from './Sidebar.module.scss';
import {classNames} from '006_Shared/lib/classNames';
import {useState} from 'react';
import {ThemeSwitcher} from '003_Widgets/ThemeSwitcher';
import {LangSwitcher} from '003_Widgets/LangSwitcher';

interface SidebarProps {
    className?: string;
}

export const Sidebar = (props: SidebarProps) => {

    const {className = ''} = props;

    const [collapsed, setCollapsed] = useState(false)

    const onToggle = () => {
        setCollapsed(prev => !prev)
    }

    return (
        <div className={classNames(cls.Sidebar, {[cls.collapsed]: collapsed}, [className])}>
            <button onClick={onToggle}>toggle</button>
            <div className={cls.switchers}>
                <ThemeSwitcher />
                <LangSwitcher className={cls.lang} />
            </div>
        </div>
    );
};
