import {classNames} from '006_Shared/lib/classNames';
import {Button} from '006_Shared/ui/Button/Button';
import {useEffect, useState} from 'react';

interface BugButtonProps {
    className?: string;
}

export const BugButton = (props: BugButtonProps) => {

    const {className = ''} = props;

    const [error, setError] = useState(false)

    const throwErr = () => setError(true)

    useEffect(() => {
        if (error) {
            throw new Error()
        }

    }, [error]);

    return (
        <Button
            className={classNames('', {}, [className])}
            onClick={throwErr}>
            Throw Error
        </Button>
    );
};
