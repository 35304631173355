import React, {Suspense} from 'react';
import {useTheme} from '001_App/providers/ThemeProvider';
import {classNames} from '006_Shared/lib/classNames';
import {AppRouter} from '001_App/providers/router';
import {Navbar} from '003_Widgets/Navbar';
import {Sidebar} from '003_Widgets/Sidebar';

function App() {

    const {theme} = useTheme();

    // useEffect(() => {
    //     if(Math.random() < 0.5) {
    //         throw new Error
    //     }
    // })

    return (
        <div className={classNames('app', {}, [theme])}>
            <Suspense fallback={''}>
                <Navbar />
                <div className="content-page">
                    <Sidebar />
                    <AppRouter />
                </div>
            </Suspense>
        </div>
    );
}

export default App;
