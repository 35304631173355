import {Theme, ThemeContext} from '001_App/providers/ThemeProvider/lib/ThemeContext';
import {useContext} from 'react';
import {LOCAL_STORAGE_THEME_KEY} from '001_App/providers/ThemeProvider';

interface UseThemeResult {
    toggleTheme: () => void;
    theme: Theme;
}

export function useTheme (): UseThemeResult {

    const a = useContext(ThemeContext)
    if (a === null) {
        throw Error;
    }

    const {theme, setTheme} = a;

    const toggleTheme = () => {

        const newTheme = theme === Theme.DARK ? Theme.LIGHT : Theme.DARK
                setTheme(newTheme);
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme)
    }
    return {theme, toggleTheme}
}
