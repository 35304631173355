import cls from './ErrorPage.module.scss';
import {classNames} from '006_Shared/lib/classNames';
import {useTranslation} from 'react-i18next';
import {Button} from '006_Shared/ui/Button/Button';

interface ErrorPageProps {
    className?:string;
}

export const ErrorPage = (props: ErrorPageProps) => {

    const {className = ''} = props;

    const {t} = useTranslation();

    const reloadPage = () => {
        window.location.reload()
    }

    return (
        <div className={classNames(cls.ErrorPage, {}, [className])}>
            <p>An unexpected error has occurred</p>
            <button onClick={reloadPage}>Reload page</button>
        </div>
    );
};
