import React, {createContext} from 'react';

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
}

export type ThemeContextProps = {
    theme: Theme;
    setTheme: React.Dispatch<React.SetStateAction<Theme>>;
}

export const ThemeContext = createContext<ThemeContextProps | null>(null);
