import cls from '003_Widgets/LangSwitcher/ui/LangSwitcher.module.scss';
import {classNames} from '006_Shared/lib/classNames';
import {Button, ThemeButton} from '006_Shared/ui/Button/Button';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

interface LangSwitcherProps {
    className?: string;
}

export const LangSwitcher = (props: LangSwitcherProps) => {

    const {className = ''} = props;
    const {t} = useTranslation()

    const toggle = async () => {
        i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru').then()
    }

    return (
        <Button className={classNames(cls.LangSwitcher, {}, [className])}
                theme={ThemeButton.CLEAR}
                onClick={toggle}>

            {t('Язык')}

        </Button>
    );
};
