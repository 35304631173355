import cls from './Navbar.module.scss';
import {classNames} from '006_Shared/lib/classNames';
import React from 'react';
import {AppLink, AppLinkThemes} from '006_Shared/ui/AppLink/AppLink';
import {useTranslation} from 'react-i18next';

interface NavbarProps {
    className?: string;
}

export const Navbar = (props: NavbarProps) => {

    const {className = ''} = props;

    const {t} = useTranslation()

    return (
        <nav className={classNames(cls.Navbar, {}, [className])}>
            <div className={cls.links}>
                <AppLink to={'/'} theme={AppLinkThemes.PRIMARY}>
                    {t('Главная')}
                </AppLink>

                <AppLink to={'/about'} theme={AppLinkThemes.PRIMARY}>
                    {t('О компании')}
                </AppLink>
            </div>

        </nav>
    );
};
