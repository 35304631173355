import cls from './NotFoundPage.module.scss';
import {classNames} from '006_Shared/lib/classNames';
import {useTranslation} from 'react-i18next';

interface NotFoundPageProps {
    className?:string;
}

export const NotFoundPage = (props: NotFoundPageProps) => {

    const {className = ''} = props;

    const {t} = useTranslation()

    return (
        <div className={classNames(cls.NotFoundPage, {}, [className])}>
            {t('Страница не найдена')}
        </div>
    );
};
