import cls from '006_Shared/assets/icons/iconsComponent/iconComponents.module.scss';
import {classNames} from '006_Shared/lib/classNames';
import {ReactComponent as LightIcon} from '../svg/theme-light.svg';
import {ReactComponent as DarkIcon} from '../svg/theme-dark.svg';

interface IconComponentsProps {
    className?: string;
}

export const IconDarkComponent = (props: IconComponentsProps) => {

    const {className = ''} = props;

    return (
        <DarkIcon
            className={classNames(cls.IconComponents, {}, [className])}
        />)
}

export const IconLightComponent = (props: IconComponentsProps) => {

    const {className = ''} = props;

    return (
        <LightIcon
            className={classNames(cls.IconComponents, {}, [className])}
        />)
}
