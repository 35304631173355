import cls from './PageLoader.module.scss';
import {classNames} from '006_Shared/lib/classNames';
import {Loader} from '006_Shared/ui/Loader/Loader';

interface PageLoaderProps {
    className?: string;
}

export const PageLoader = (props: PageLoaderProps) => {

    const {className = ''} = props;

    return (
        <div className={classNames(cls.PageLoader, {}, [className])}>
            <Loader />
        </div>
    );
};
