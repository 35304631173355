import {ReactNode, useMemo, useState} from 'react';
import {Theme, ThemeContext, ThemeContextProps} from '001_App/providers/ThemeProvider';


interface ThemeProviderProps {
    children: ReactNode;
}

export const LOCAL_STORAGE_THEME_KEY = 'theme';

export const ThemeProvider = ({children}: ThemeProviderProps) => {

    const userTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as Theme || Theme.LIGHT;

    const [theme, setTheme] = useState<Theme>(userTheme)

    const defaultProviderValue: ThemeContextProps = useMemo(() => ({
        theme: theme,
        setTheme: setTheme,
    }), [theme]);

    return (
        <ThemeContext.Provider value={defaultProviderValue}>
            {children}
        </ThemeContext.Provider>
    )
}
